import clsx from 'clsx';
import {DiagonalWrapperContext} from 'context/DiagonalWrapperContext';
import {useBackgroundStyleConfigs} from 'hooks/useBackgroundStyleConfigs';
import Image from 'next/image';
import React, {FC, useContext} from 'react';

import {TypoComponentsContentSectionBgImage} from 'lib/api/strapi';

import {Box, Grid, Typography} from 'components/basic-components';
import {ButtonWithConfig} from 'components/basic-components/Button/ButtonWithConfig';
import MarkdownContent from 'components/basic-components/MarkdownContent';
import {withErrorBoundary} from 'components/error/ErrorBoundary';

import styles from './ContentSectionImageBackground.module.scss';
import {Theme, useMediaQuery} from "@mui/material";

const ContentSectionImageBackground: FC<TypoComponentsContentSectionBgImage> = ({
  title,
  titleLevel,
  subtitle,
  subTitleLevel,
  text,
  button,
  image,
  imageMobile,
  hideImageOnMobile,
  noImagePaddingBottomOnMobile
}) => {
  const {textColor, backgroundColor} = useBackgroundStyleConfigs();
  const {diagonal, topElement, botElement, absolute} = useContext(DiagonalWrapperContext);
  const isDesktop = useMediaQuery((theme: Theme) => theme.breakpoints.up('md'));
  return (
    <Box
      className={clsx({
        [styles.boxWrapper]: true,
        [styles.noImagePaddingBottomOnMobile]: noImagePaddingBottomOnMobile && !isDesktop
      })}
      sx={{backgroundColor: backgroundColor, color: textColor}}
    >
      <Grid container columnSpacing={{xxs: 0, md: '1%'}}>
        <Grid xxs={12} md={5.75}>
          <Box className={styles.textWrapper}>
            <Typography
              className={styles.title}
              sx={{typography: {xxs: 'titleMobile', md: 'h1'}}}
              component={titleLevel}
            >
              {title}
            </Typography>
            <Typography
              className={styles.subtitle}
              sx={{typography: {xxs: 'footerCards', md: 'h2'}}}
              component={subTitleLevel}
            >
              {subtitle}
            </Typography>
            <MarkdownContent className={styles.markdown} content={text} />
            <ButtonWithConfig buttonProps={button} useThemeStyle />
          </Box>
        </Grid>
        <Grid
          className={clsx({
            [styles.imageWrapper]: true,
            [styles.hideImageOnMobile]: hideImageOnMobile
          })} xxs={12} md={6.25}>
          <Image
            className={clsx({
              [styles.image]: true,
              [styles.imageDiagonal]:
                (diagonal.top && diagonal.bottom) ||
                (topElement.isDiagonal && botElement.isDiagonal && !absolute),
            })}
            alt={
              isDesktop
                ? image?.data?.attributes?.alternativeText
                : imageMobile?.data?.attributes?.alternativeText
                  ? imageMobile?.data?.attributes?.alternativeText
                  : image?.data?.attributes?.alternativeText
            }
            src={
              isDesktop
                ? image?.data?.attributes?.url
                : imageMobile?.data?.attributes?.url
                  ? imageMobile?.data?.attributes?.url
                  : image?.data?.attributes?.url
            }
            height={
              isDesktop
                ? image?.data?.attributes?.height
                : imageMobile?.data?.attributes?.height
                  ? imageMobile?.data?.attributes?.height
                  : image?.data?.attributes?.height
            }
            width={
              isDesktop
                ? image?.data?.attributes?.width
                : imageMobile?.data?.attributes?.width
                  ? imageMobile?.data?.attributes?.width
                  : image?.data?.attributes?.width
            }
          />
        </Grid>
      </Grid>
    </Box>
  );
};

export default withErrorBoundary(ContentSectionImageBackground, ' ContentSectionImageBackground');
